import React, { useEffect, useState, useMemo } from 'react';
import { Storage } from 'aws-amplify';

function App() {

  const [news,setNews] = useState([])
  const [topNews,setTopNews] = useState([])


  useEffect(() => {
    const f = async () => {

      const list = await Storage.list('', {
        customPrefix: { public: 'news/'}
      })

      const newsList = list.filter((item, i) => {
        const regexp = /^\d{8}_10sec|news_vol\d{1,}\.pdf$/
        return regexp.test(item.key);
      })

      let keys = newsList.filter((item, i) => {
        return newsList.length >= i + 6
      })

      keys = keys.map((item, i) => {
        return item.key
      })

      setNews(keys)

      let topKeys = newsList.filter((item, i) => {
        return newsList.length < i + 6
      })

      topKeys = topKeys.map((item, i) => {
        return item.key
      })

      setTopNews(topKeys.reverse())

    };

    f();

  },[])

  return (
    <div className="is-preload">


      {/* 共通ナビゲーションメニュー */}
      <div className="mobile-menu">
          <ul>
            <li><a href="#top">TOP</a></li>
            <li><a href="#election">私の原点</a></li>
            <li><a href="#day_work">活動</a></li>
            <li><a href="#first">プロフィール</a></li>
            <li><a href="#articles">取材記事</a></li>
            <li><a href="#news">市政ニュース</a></li>
            <li><a href="#policys">政策</a></li>
            <li><a href="#job">市議会議員の仕事</a></li>
            <li><a href="#supporter">後援会</a></li>
            <li><a href="#contact">連絡先</a></li>
          </ul>
        </div>

      
      <div id="wrapper">
        <section id="top" className="intro">
          <header>
            <h1>
              <ruby>
                <rb>加賀</rb>
                <rp>（</rp>
                <rt style={{ fontSize: "0.5em" }}>かが</rt>
                <rp>）</rp>
              </ruby>
              ななえ
            </h1>
            <p>
              <strong>オフィシャルサイト</strong>
              <br />
              <br />
              富士見市議会議員
              <br />
              市民にいちばん身近な政治家
              <br />
              現職最年少 {useMemo(() => {
                const today = new Date();
                const birthDate = new Date(1991, 4, 19); // JavaScriptの月は0から始まるため、5月は4
                let age = today.getFullYear() - birthDate.getFullYear();
                const m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                  age--;
                }
                return age;
              }, [])}歳
              <br />
              会派「草の根」
            </p>
          </header>
          <div className="content">
            <span className="image fill" data-position="center">
              <img src="images/top.jpg" alt="" />
            </span>
          </div>
        </section>

        <section id="report">
          <header>
            <h2>
              富士見市議会議選挙<br/>当選のご報告<br/>2025年3月30日
            </h2>
          </header>
          <div className="content">

            <h3 style={{marginTop: "3rem", marginBottom: "0"}}>富士見市議会議選挙 当選のご報告 2025.3.23</h3>
            <div style={{position: "relative", width: "100%", paddingTop: "56.25%", marginTop: "2rem", marginBottom: "2rem"}}>
              <iframe
                style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: 0}}
                src="https://www.youtube.com/embed/lIf7Uxwxoqc?si=5jvW5Np2PKU2Z9gA"
                title="富士見市議会議員選挙　当選のご挨拶 "
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
              
            <div>
            <h4>【当選のご報告】</h4>
              <p style={{ marginBottom: "2rem" }}>
                このたび行われた富士見市議会議員選挙において、3,682票をいただき、4期目の当選を果たすことができました。<br/><br/>

                投票率が下がる中、前回を大きく上回る得票をいただけたことは、3期目の活動を皆さんに評価していただけたのだと感じ、安堵すると同時に、一層身の引き締まる重いです。<br/>
                ご支援、ご期待に心より感謝いたします。<br/><br/>
                
                選挙期間中、「これまでずっと見ていました」「今日たまたま相談してみようと思った」など、かけていただいた言葉の一つひとつからつながりを持ち続けることの大切さを感じました。これからの4年間、出会ったお一人おひとりの声を、より丁寧にお聞きしていく所存です。<br/><br/>

                明日も始発から駅に立ちます。そして4期目も、さらに多くの方々の声を聴き、かたちにしていくことをここにお約束いたします。
              </p>
            </div>

            <div style={{ textAlign: "right", marginBottom: 30 }}>
              <img src="images/sign.png" alt="" style={{ width: "8em" }} />
            </div>

          </div>
        </section>


        
        <section id="election" className="policys" style={{ marginTop: "0" }}>
          <header>
            <h2>
              私が政治の道を志した原点
            </h2>
          </header>
          <div className="content">
            <h3 style={{ marginTop: "2rem" }}>
              きっかけ
            </h3>
            <p>
              私は、不登校になり、学校に行けなかった子ども時代、ずっと「学校に行けない自分が悪い」と苦しく思っていました。
              <br /><br />
              しかし、大学時代に訪れたスペインで「不登校」という言葉はなく、「ホームスクーリング」と呼ばれていることを知ったとき、気づきました。
              <br /><br />
              <strong> ――ずっと私が悪いと自分を責めてきたけれど、私に学ぶ環境を与えてこなかった社会に問題があるのではないかと。</strong>
              <br /><br />
              その後、政治や社会をもっと知りたいと思い、議員インターンシップに参加しました。
              <br /><br />
              そこで、不登校の課題だけではなく、<strong>「これまで個人の問題として矮小化されてきたことは、実は社会全体の課題であり、政治の力で社会を変えることで救われる人がいる」</strong>という現実を目の当たりにしました。
              <br /><br />
              そのときから、<strong>「社会を変えたい」「政治を変えたい」</strong>と強く思い続け、皆さまのお力で市議会議員に当選することができました。
            </p>

            <h3>
              市民の皆様の声とともに歩んだ7年間
            </h3>
            <p>
            7年前に初当選して以来、私は毎朝、駅に立ち続けてきました。
            <br /><br />
            直接、市民の皆さまの声を伺うことを何よりも大切にしてきたのは、<strong>私自身がかつて、そして今もなお「政治に声を届けたい」と切実に願い続けているからです。</strong>
            <br /><br />
            そうして市民の声に耳を傾ける中で、私は確信しました。
            <br /><br />
            <strong>――政治が見つめるべき課題は、駆け引きや政局ではなく、市民お一人ひとりの暮らしの中にこそあるのだ、と。</strong>
            </p>

            <h3>
              これからへの想い
            </h3>
            <p>
              これまでの3期、私は数えきれないほどの悩みを、市民の皆さまから直接お聞きしてきました。
              <br />
              特に3期目で伺った中には、人に言えずに抱え込んでいた心の声もありました。
              <br/><br/>
              <strong>しかし、そうした切実な声から生まれた政策を実現できた時、市民の生活が少しずつ変わっていく――その瞬間に、私は議員としての使命を強く実感しています。</strong>
            </p>

            <h3>
              市民の声に寄り添うために
            </h3>
            <p>
            <strong>私は、この仕事を通して、市民の皆さまの痛みや苦しみに耳を傾け、その思いを政治に届け続けたいと考えています。<br/>
            そして、一人でも多くの方が「声をあげてよかった」と思える社会をつくるために、全力を尽くしていきます。</strong>
            </p>


            <div style={{ textAlign: "right", marginBottom: 30 }}>
              <img src="images/sign.png" alt="" style={{ width: "8em" }} />
            </div>

            <img
              src="images/station.jpg"
              alt=""
              style={{ width: "100%", marginTop: "3%" }}
            />
            <img src="images/poster.jpg" alt="" style={{ width: "100%" }} />

          </div>

        </section>


        <section id="day_work">
          <header>
            <h2>日々の活動</h2>
          </header>
          <div className="content">
            <img
              src="images/katsudou.png"
              alt=""
              style={{ width: "5em", margin: "2rem auto", display: "block" }}
            />
            <p style={{ textAlign: "center" }}>
              <strong>
                私は市政の透明性を高めるために、<br/>
                SNSでの活動報告を毎日更新しております。<br/>
                SNSの投稿をぜひご覧ください。
            </strong>
            </p>
            <div className="sns">
              <a href="https://twitter.com/Nanaekaga" target="_blank" rel="noreferrer">
                <img src="images/xLogo.png" alt="" />
              </a>
              <a href="https://www.instagram.com/nanae.kaga/" target="_blank" rel="noreferrer">
                <img src="images/InstagramLogo.png" alt="" />
              </a>
              <a href="https://www.facebook.com/kaga.nanae" target="_blank" rel="noreferrer">
                <img src="images/FBLogo.png" alt="" />
              </a>
            </div>
          </div>
        </section>


        <section id="first">
          <header>
            <h2>
              プロフィール
            </h2>
          </header>
          <div className="content">
            <p>
            1991年5月19日、ふじみ野生まれ。AB型。<br/><br/>
            小中学校は不登校。<br/>高校中退後、高等学校卒業程度認定試験(大検)を取得。<br/><br/>
            2014年、明治大学政治経済学部政治学科を卒業後、針ヶ谷保育園、センター21障がい者自立支援施設に勤務。<br/><br/>
            <strong>2016年7月に富士見市議会議員補欠選挙に25歳で初当選。<br/>
            富士見市議会議員として、現在4期目。
            </strong>
            </p>

            <img src="images/gikai1.png" alt="一般質問" style={{ width: "80%", display: "block", margin: "0 auto 1rem auto" }} />
            <img src="images/gikai2.png" alt="一般質問" style={{ width: "80%", display: "block", margin: "0 auto" }} />

          </div>
        </section>


        <section id="articles">
          <header>
            <h2>
              取材を受けた記事
            </h2>
          </header>
          <div className="content">

            <h3>市議会議員としての活動に関する記事</h3>
            <div style={{marginBottom: "4rem"}}>
              <a href='https://futoko-online.jp/interview/2143/' target='_blank' rel="noreferrer">
                不登校オンライン(2022年10月31日)【全文公開】<br/>
                私のゴールは不登校による差別をなくすこと<br/>
                いじめ、不登校を経験した市議会議員の思い
              </a>
            </div>

            <h3>2023年2月26日発信<br/>【政策の変遷/埼玉県LGBT条例基本計画に対するパブコメについて】に関連する記事</h3>
            <div style={{marginBottom: "0"}}>
              <a href='https://encount.press/archives/424454/' target='_blank' rel="noreferrer">
                ENCOUNT編集部
              </a><br/>
              <a href='https://www.sankei.com/article/20230307-LHITCRED6ZPC3EWAGNBF6ELKB4/' target='_blank' rel="noreferrer">
                産経新聞
              </a>
            </div>
          </div>
        </section>

        <section id="news" className="news">
          <header>
            <h2>市政ニュース</h2>
          </header>
          <div
            className="content"
            style={{
              textAlign: "center",
              display: "inline-flex",
              flexDirection: "column"
            }}
          >
            <img
              src="images/news.png"
              alt=""
              style={{ width: "12em", margin: "0 auto" }}
            />


            {topNews.map((n, i) => {
              if(!n) return null;
              
              let ns = n.split('_')
              
              return(
                <a
                  href={"news/" + n}
                  target="_blank"
                  className="button primary large"
                  key={i} rel="noreferrer"
                >
                  { ns[1] === 'news' ? '市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3) : '10秒で読める市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3)}
                  <br />
                  {'(' + ns[0].slice(0,4) + '/' + ns[0].slice(4,6) + '/' + ns[0].slice(6,8) + ' 公開)'}
                </a>
              )
            })}


            <details style={{ paddingTop: "2rem" }}>
              <summary style={{ textAlign: "center", outline: 0 }}>
                もっと見る (バックナンバー)
              </summary>
              <ul className="actions">

                {news.map((n, i) => {
                  if(!n) return null;
                  
                  let ns = n.split('_')
                  
                  return(
                    <li key={i}>
                      <a
                        href={"news/" + n}
                        target="_blank"
                        className="button primary large" rel="noreferrer"
                      >
                        { ns[1] === 'news' ? '市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3) : '10秒で読める市政ニュース ' + ns[2].slice(0, -4).slice(0,3) + '.'  + ns[2].slice(0, -4).slice(3)}
                        <br />
                        {'(' + ns[0].slice(0,4) + '/' + ns[0].slice(4,6) + '/' + ns[0].slice(6,8) + ' 公開)'}
                      </a>
                    </li>
                  )
                })}


              </ul>
            </details>
          </div>
        </section>

        
        <section id="policys" className="policys">
          <header>
            <h2>加賀ななえの政策</h2>
          </header>
          <div className="content">
              <h3 style={{ fontSize: "1.2rem", marginTop: "2rem"}}>政策に込める私の信念</h3>
              <p style={{ marginBottom: "4rem" }}>
                <strong style={{fontSize: "1.2rem"}}>「市民の声が政治に届く、公平・公正な社会をつくるー」</strong>
              </p>

              <div className="sentence policy" style={{ overflow: "hidden" }}>
                <div className="image right">
                  <img src="images/p1.png" alt="" style={{width : "100%", marginLeft: "20%"}}/>
                </div>
                <h3>教育</h3>
                <h4>多様な学びの実現</h4>

                <h5>実現したこと</h5>
                <h6>学校に行かない・行けない子どもへの理解の変化</h6>
                <ul>
                  <li>適応指導教室の名称が「教育支援センター」に変更。子どもの学びを支援する場としての認識が広がりました。</li>
                  <li>教育行政方針も「不登校児童生徒の解消」から「多様な学びの場の確保」「特性や願いに寄り添い、社会的自立を目指す」へと大幅に改善。具体的取組も充実し、子どもの状況に寄り添う方針が明確化されました。</li>
                </ul>

                <h6>子どもの健康を守る</h6>
                <p className="subtitle">健康診断未受診問題の解消、第一歩へ</p>
                <ul>
                  <li>定期健康診断を欠席した児童生徒が、健康増進センターで別日に再受診できるように（令和6年度から）。不登校の児童生徒も健康診断を受けやすくなりました。</li>
                </ul>

                <h6>多様な学びの場の確保</h6>
                <p className="subtitle">居場所づくりの推進</p>
                <ul>
                  <li>「出張あすなろ」が市内の各公共施設で開催されるように。教育支援センターの支援員が各施設へ赴き、子どもの居場所を増やす取り組みを実現しました。</li>

                  <li>教育相談室と教育支援センターの移転<br/>
                  南畑の特別支援学校内からふじみ野小学校へ移転し、駅近で通いやすくなりました。さらなる移転も検討中です。
                  </li>
                </ul>

                <div className="future-challenge">
                  <h5>これから</h5>
                  
                  <h6>現場への周知・啓発の徹底</h6>
                  <ul>
                    <li>変化した教育行政方針や支援策を教育現場へ広く周知し、実践につなげていく必要があります。</li>
                  </ul>

                  <h6>健康診断の受診機会をさらに拡充  </h6>
                  <ul>
                    <li>特定の日時ではなく、市内医療機関で柔軟に再受診ができるよう体制を整備し、一層受診しやすい環境づくりを推進します。</li>
                  </ul>

                  <h6>オンライン学習環境の充実    </h6>
                  <ul>
                    <li>タブレット端末を活用し、自宅や施設などどこからでも学べる環境を整えることで、多様な子どもの学習をしっかり支援します。</li>
                  </ul>

                </div>
              </div>

              <div className="sentence policy" style={{ overflow: "hidden" }}>
                <h3>女性ひとりでも安心して暮らせる社会へ</h3>
                <h4>女性が誰かの妻や娘、母としてではなく、一人の人間として生きられる社会を目指します</h4>

                <div style={{ textAlign: 'right', marginBottom: '-2rem', marginTop: '-2.5rem' }}>
                  <img src="images/p2.png" alt="" style={{ width: '30%' }} />
                </div>

                <h5>実現したこと</h5>
                <h6>女性に対する暴力の防止と相談体制の拡充</h6>
                <ul>
                  <li>市に配偶者暴力相談支援センターを設置。相談・カウンセリング、住民票異動や生活保護の手続きなどの一元化、一時保護の同行支援が可能になり、「ワンストップ」の支援を担う機能が整いました。</li>
                </ul>

                <h6>「性犯罪の厳罰化を求める意見書」「性犯罪の実態に即した刑法の更なる改正を求める意見書」の提出・可決</h6>
                <ul>
                  <li>令和4年9月議会で全会一致により可決され、令和5年6月の性犯罪関係法改正の一助となりました（会派で取り組みました）。</li>
                </ul>

                <div className="future-challenge">
                  <h5>これから</h5>
                  
                  <h6>夜間・休日対応の強化</h6>
                  <ul>
                    <li>配偶者暴力相談支援センターについて相談員のシフト配置や電話・オンライン相談を含め、24時間体制へ近づく相談環境の整備を目指します。</li>
                  </ul>

                  <h6>市議会の意見書提出権を活用した女性への暴力防止策の働きかけ</h6>
                  <ul>
                    <li>国への意見書について、積極的な提出を目指します。</li>
                  </ul>

                  <h6>防犯対策・都市環境の整備</h6>
                  <ul>
                    <li>市の取り組みとして、防犯対策や都市環境を充実させ、昼夜問わず女性がひとりでも安心して暮らせるまちを目指します。</li>
                    <li style={{ fontSize: '0.9rem', color: '#555', listStyle: 'none', marginLeft: '-1rem' }}>主な取り組み内容：
                      <ul style={{ fontSize: '0.85rem', color: '#555', lineHeight: '1.5', marginTop: '0.2rem' }}>
                        <li style={{marginBottom: "0.2rem"}}>昼夜問わず安心して歩ける歩道・公園づくり</li>
                        <li style={{marginBottom: "0.2rem"}}>暗い路地や公園の街灯・防犯カメラの設置</li>
                        <li style={{marginBottom: "0.2rem"}}>見通しを確保するための植栽やフェンスの配置見直し</li>
                        <li style={{marginBottom: "0.2rem"}}>防犯アプリの導入</li>
                        <li style={{marginBottom: "0.2rem"}}>市・警察・地域住民が一体となった街頭パトロール体制の強化</li>
                        <li style={{marginBottom: "0.2rem"}}>民間住宅への防犯カメラ・センサーライト設置助成の導入</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="sentence policy" style={{ overflow: "hidden" }}>
                <div className="image right">
                  <img src="images/p3.png" alt="" style={{width : "55%", marginLeft: "45%"}}/>
                </div>
                <h3>防災</h3>
                <h4>市民の生命・財産を守る</h4>

                <h5>実現したこと</h5>
                <h6>防災関係の出前講座メニューの充実</h6>
                <ul>
                  <li>市民の皆さまが地域の身近な場所で防災知識を学べるよう、講座の種類と開催機会が拡大されました。</li>
                </ul>

                <h6>市民参加型の災害ボランティアセンター運営体制の検討</h6>
                <ul>
                  <li>災害時に地域住民が主体的に運営に関われるよう、ボランティア養成講座の実施が検討されています。</li>
                </ul>

                <div className="future-challenge">
                  <h5>これから</h5>
                  
                  <h6>インフラ整備を通じて、防災・減災に取り組む</h6>
                  <ul>
                    <li>老朽化インフラの更新や公共施設マネジメントを強化し、市民の生命と財産を守ります。</li>
                  </ul>

                  <h6>老朽化インフラの更新と強化</h6>
                  <ul>
                    <li>上下水道<br/>
                    AIやデータ分析を活用し、管の劣化リスクを数値化。優先度に基づく効率的な改修計画を提案します。</li>
                    <li>道路<br/>
                    「道路損傷通報システム」の利用率向上のため、LINEやアプリとの連携を推進。市民が簡単に通報できる仕組みを目指します。</li>
                  </ul>

                  <h6>公共施設マネジメント</h6>
                  <ul>
                    <li>新庁舎や多目的屋外スポーツ施設の整備<br/>
                    新規施設と既存施設の維持管理をバランスよく進め、長期的な財政負担や利用状況を総合的に検証。持続可能な運営を提案します。</li>
                  </ul>
                </div>
              </div>

              <div className="sentence policy" style={{ overflow: "hidden" }}>
                <div className="image right">
                  <img src="images/p4.png" alt="" style={{width : "70%", marginLeft: "30%", marginTop: "5%"}}/>
                </div>
                <h3>公共交通</h3>
                <h4>移動に困らないまちへ</h4>

                <h5>実現したこと</h5>
                <h6>ダイチャリ（シェアサイクル）ステーションの拡充</h6>
                <ul>
                  <li>市内各地へのステーションの拡充により、気軽に自転車を利用できる環境が充実しました。</li>
                </ul>

                <h6>デマンドタクシー（タクシー補助制度）の本格運行</h6>
                <ul>
                  <li>実証実験後に本格導入。移動手段の一つとして高齢者の皆さまを中心に利用されています。</li>
                </ul>

                <div className="future-challenge">
                  <h5>これから</h5>
                  
                  <h6>デマンドタクシーをアプリで手軽に</h6>
                  <ul>
                    <li>アプリを活用し、予約・決済をよりスムーズに行える仕組みを提案します。</li>
                  </ul>

                  <h6>自動運転バスの実証実験・試験運行</h6>
                  <ul>
                    <li>限られた区間で実施し、安全面や費用対効果を検証することを提案します。</li>
                  </ul>

                  <h6>将来の交通環境との連動</h6>
                  <ul>
                    <li>バス・電車・タクシー・シェアサイクルなどを一体的に管理し、スマホひとつで検索・予約・支払いが完結する仕組みを提案します。</li>
                  </ul>
                </div>
              </div>
          </div>
            
        </section>
        


        <section id="job" className="job">
          <header>
            <h2>市議会議員の仕事とは？</h2>
          </header>
          <div className="content">
            
            <div className="sentence overview" style={{ marginBottom: "3rem", marginTop: "2rem" }}>
              <h3>市議会議員の主な仕事内容</h3>
              <p>
              市議会議員の最も基本的な仕事は、市議会の本会議や委員会に出席し、以下のような事項について審議・承認を行うことです。
              </p>
                <ul>
                  <li>条例の制定・改廃</li>
                  <li>予算の決定</li>
                  <li>決算の認定</li>
                  <li>重要な契約の締結</li>
                  <li>財産の取得・処分 など</li>
                </ul>
              <p>
              市議会議員は、市民の代表として、提出された議案やその実施理由が本当に合理的であるかを慎重に判断し、適切な意思決定を行う責任を担っています。
              </p>
            </div>

            <div className="sentence question">
              <h3>市議会議員の仕事の成果と一般質問について</h3>
              <p>
              富士見市議会議員として仕事の成果を考えたとき、議決に関わることだけでなく、非常に重要だと考えているものがあります。
              それは、一般質問を通じて市民の方々の要望を市政に反映させることです。<br/><br/>

              一般質問とは、市政全般に関して、議員が市長をはじめとする行政側に現状や見通しを質問する場です。この機会を活用し、市民生活をより良くするための政策や、市民の方々からいただいた要望を行政側に伝えて検討してもらうことで、実際の政策実現につなげることができます。<br/><br/>

              富士見市では、すべての議員にこの一般質問の機会が与えられています。私はこの貴重な場を最大限に活かし、地域活動や駅頭などで直接うかがった市民の課題を議会で問題提起し、より良い市政を実現するために取り組んでいます。そして、一般質問で取り上げた内容が具体的な施策として実現することを、議員としての仕事の成果、さらには重要な指標だと位置付けています。
              </p>

              <h3>一般質問から政策へつながる例</h3>
              <div className="questionflow clearfix">
                <h4>市民の声</h4>
                <img src="images/q1.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  学校へ行かない子どもが定期健康診断を受診できず、健康状態が心配である。
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h4>調査・研究</h4>
                <img src="images/q2.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  勉強会への出席、先進事例の視察、その他資料での調査などを行って、一般質問の準備をする。
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h4>一般質問当日</h4>
                <img src="images/q3.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  「医師会と連携し、健康診断を欠席した児童生徒に対して市内病院で柔軟に健康診断を受診できる仕組みを！」
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h4>行政側の答弁</h4>
                <img src="images/q4.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  「課題として認識し、仕組みを検討します。」
                </p>
              </div>
              <div className="box-img-down">
                <img src="images/down.png" alt="down" style={{ width: 30 }} />
              </div>
              <div className="questionflow clearfix">
                <h4>政策の実現（成果）</h4>
                <img src="images/q5.png" alt="" />
                <p style={{ margin: 0, overflow: "hidden" }}>
                  健康診断を欠席した児童に対して、学校外での健康診断の予備日が設けられました！
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="supporter">
          <header>
            <h2>
              加賀ななえ後援会
              <br />
              「ななえ会」
              <br />
              ご入会はこちらから
            </h2>
          </header>
          <div className="content">
            <img
              src="images/kochira.png"
              alt=""
              style={{ width: "6em", margin: "5% 40%" }}
            />
            <h3>市民の皆様へ</h3>
            <p style={{ marginBottom: "3rem" }}>
            ご入会いただいた方には、市政ニュースなどの刊行物や、 タウンミーティングのご案内等をさせていただいております。<br/><br/>
            富士見市の市政を身近に感じていただきたいと思っておりますので、 どうぞお気軽にご入会ください。
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://docs.google.com/forms/d/1AGhT07cOUSg--_9WLNt-jHAsHRPhYxqulkjEQdeNTxE/viewform"
                  className="button primary large"
                  style={{ fontSize: "100%" }}
                >
                  ご入会はフォームへ
                </a>
              </li>
            </ul>
            <p style={{ fontSize: "80%" }}>
              ※入会費・年会費等は一切かかりません
              <br />
              ※ご記入いただいた個人情報については、後援会活動以外には利用いたしません。また、厳重に管理・保護の上、その取扱につきましては、法令その他の規範を遵守し細心の注意を払っております。
            </p>
          </div>
        </section>
        <section id="contact">
          <header>
            <h2>連絡先</h2>
          </header>
          <div className="content">
              
              <h3 style={{margin: "0 0 2rem 0"}}>市政に関するご要望やご質問など、お気軽にお寄せください。</h3>
              
              <h4 style={{margin: "0 0 0 0", fontSize: "1.2rem", fontWeight: "600"}}>Email</h4>
              <p style={{margin: "0 0 3rem 0"}}><a href="mailto:kagananae@gmail.com">kagananae@gmail.com</a></p>
                
              
              <h4 style={{margin: "0 0 0 0", fontSize: "1.2rem", fontWeight: "600"}}>Phone</h4>
                <p style={{margin: "0 0 3rem 0"}}><a href="tel:08040517711">(080) 4051-7711</a></p>

              <h4 style={{margin: "0 0 0 0", fontSize: "1.2rem", fontWeight: "600"}}>Address</h4>
                <p style={{margin: "0 0 3rem 0"}}>
                  〒354-0024
                  <br />
                  富士見市鶴瀬東 2-18-14
                  <br />
                  クリオ鶴瀬壱番館 306
                </p>
                  
            
          </div>
        </section>
        <div className="copyright">© ななえ会. All rights reserved.</div>
      </div>

    </div>

  );
}

export default App;
